import React from 'react';
import './SocialIcons.css'
import callIcon from './phone-icon.svg'
import wspIcon from './whatsapp-icon.svg'
import igIcon from './instagram-icon.svg'
import fbIcon from './facebook-icon.svg'
import mail from './mail-icon.svg'
import catalog from './catalog-icon.svg'
import waze from './waze-icon.svg'
import website from './web-icon.svg'

const handleClick = (action) => {
    switch (action) {
        case "Call":
            window.location.href = 'tel:046610055'
            break;
        case "Wsp":
            window.open('https://wa.link/qghfqa')
            break;
        case "IG":
            window.open('http://instagram.com/patron.ltd/')
            break;
        case "FB":
            window.open('https://www.facebook.com/elpatronltd')
            break;
        case "Mail":
            window.location.href = 'mailto:info@patronltd.com'
            break;
        case "Website":
            window.open('https://patronltd.com')
            break;
        case "waze":
            window.open('https://waze.com/ul/hsvc43xeby')
            break;
        case "catalog":
            window.open('https://menu-patron.qbmedia.co.il')
            break;
        default:
            console.log("Invalid action");
    }
};


const icons = [
    { "action": "Call", "svg": callIcon, "text": "חייג" },
    { "action": "Wsp", "svg": wspIcon, "text": "ואטסאפ" },
    { "action": "IG", "svg": igIcon, "text": "אינסטה" },
    { "action": "FB", "svg": fbIcon, "text": "פייסבוק" },
    { "action": "Mail", "svg": mail, "text": "דואר" },
    { "action": "catalog", "svg": catalog, "text": "קטלוג" },
    { "action": "Website", "svg": website, "text": "אתר" },
    { "action": "waze", "svg": waze, "text": "waze" },
]

const SocialIcons = () => {

    return (
        <div className='SocialIcons'>
            {icons.map(icon => (
                <div key={icon.action} onClick={e => handleClick(e.target.alt)} className="socialIcon">
                    <img src={icon.svg} alt={icon.action} />
                    <span>{icon.text}</span>
                </div>
            ))}
        </div>

    )
}

export default SocialIcons