import React from 'react';
import './Hero.css'


const Hero = () => {

    return (

        <div className='Hero'>
            <div className="cover">
                <div className="profile"></div>
            </div>
            <h1 style={{ marginTop: '75px', fontSize:'30px' }}>Patron LTD | פטרון פלדות</h1>
            <p>איתכם לפרויקט בנייה מוצלח</p>
        </div>

    )
}

export default Hero